import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import {
  CheckatradeInfo,
  HeaderBlob,
  IllustratedOption,
  Layout,
  Seo,
} from "../components/Shared"
import {
  CoverYourHome,
  GetANewBoiler,
  IllustratedOptionsSection,
} from "../components/Sections"

const Homepage = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <HeaderBlob image={data.contentfulAsset.fluid} imageDescription="">
      <h1 className="heading-extra-large">{data.headerAndSeo.headerTitle}</h1>
      <ReactMarkdown
        source={data.headerAndSeo.headerParagraph.headerParagraph}
      />
      <CheckatradeInfo />
      <div className="header-buttons">
        <Link to="/contact-us" className="button-primary">
          Contact us
        </Link>
        <Link to="/home-cover-plans" className="button-secondary">
          Cover your home
        </Link>
      </div>
    </HeaderBlob>
    <main>
      <IllustratedOptionsSection heading="Our services">
        {data.services.edges.map(({ node }) => (
          <Link
            to={`/services/${node.slug}`}
            className="illustrated-option-link"
            key={node.id}
          >
            <IllustratedOption
              heading={node.name}
              illustration={node.illustration.file.url}
              type="contentful-svg"
            >
              <p>{node.description.description}</p>
              <span className="link-with-icon illustrated-option-link-icon">
                <span className="icon-arrow-right" />
                <span className="link-with-icon-text">Find out more</span>
              </span>
            </IllustratedOption>
          </Link>
        ))}
      </IllustratedOptionsSection>
      <GetANewBoiler />
      <CoverYourHome backgroundColour="grey-lightest" />
    </main>
  </Layout>
)

export default Homepage

export const pageQuery = graphql`
  query getHomepageContent {
    site {
      siteMetadata {
        url
      }
    }
    contentfulAsset(contentful_id: { eq: "6i95ZxUrLu5TbT4ptFuBc3" }) {
      id
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid
      }
    }
    services: allContentfulServicePages(sort: { fields: name, order: ASC }) {
      edges {
        node {
          id
          description {
            description
          }
          slug
          name
          illustration {
            file {
              url
            }
          }
        }
      }
    }
    latestArticles: allContentfulBlogArticles(
      filter: { contentful_id: { ne: "7zopwST3KykcVORiCAo4y1" } }
      sort: { fields: updatedAt, order: ASC }
      limit: 3
    ) {
      edges {
        node {
          id
          featuredImage {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          featuredText {
            id
            featuredText
          }
          featuredArticle
          contentful_id
          slug
          title
          tags {
            tag
            id
          }
        }
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Homepage" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
    }
  }
`
